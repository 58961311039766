import { useState, useEffect } from 'react';
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ROLES } from "src/utils/setting";
export default function FilterDialog({
  open = false,
  handleClose,
  title,
  content,
  handleActionFirst,
  handleActionSecond,
  textBtnFirst,
  textBtnSecond,
  getListPagoda,
  options,
  dataToken,
  ...props
}) {
  const [years, setYears] = useState(0);
  const [dueDateCheck, setDueDateCheck] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Bộ Lọc
      </DialogTitle>
      <DialogContent>
        {/* {dataToken?.role_code === ROLES.SUPER_ADMIN_ROLE &&
          <>
           <Typography variant="body2" gutterBottom>
           {props.t('user_pagoda_name')}
          </Typography>
        <Autocomplete
            options={[...options]}
            getOptionLabel={(option) => option.name}
            value={options.find(e=> e.id ===  props.valueFilterPagoda) || null}
            renderOption={(props, option) => {return <li {...props}>{`${option.name}-${option.address}`}</li>}}
            onChange={(event, newValue) => {
              // onFilterPagoda(newValue?.id);
            }}
            onInputChange={(event, value, reason) => {
              if(value != "undefined" && value) {
                getListPagoda({
                  keyword: value
                })
              }
            }}
            renderInput={(params) => <TextField {...params} 
            style={{width: "200px"}}
            // onChange={(e) => {
            //   props.getListPagoda({
            //     keyword: e.target.value
            //   })
            // }}
            // label={props.t('user_pagoda_name')}
            />} 
            label="Select an option" 
          /> </>
          } */}

        <FormControl fullWidth>
          <Typography variant="body2" gutterBottom>
            Số năm kể từ lần viếng thăm gần nhất
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={years}
            label="Số năm kể từ lần viếng thăm gần nhất"
            onChange={(e) => { console.log(e.target.value); setYears(e.target.value) }}
          >
            <MenuItem value={"2"}>{"Trên 2 năm"}</MenuItem>
            <MenuItem value={"3"}>{"Trên 3 năm"}</MenuItem>
            <MenuItem value={"5"}>{"Trên 5 năm"}</MenuItem>
          </Select>
          <FormControlLabel control={<Checkbox checked={dueDateCheck}
            onChange={(event) =>  setDueDateCheck(event.target.checked)} />} label="Lọc hết hạn gửi" />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => handleActionFirst()}>{textBtnFirst || ""}</Button>
        <Button onClick={() => handleActionSecond({
          years: years,
          dueDateCheck: dueDateCheck
        })}>{textBtnSecond || "Filter"}</Button>
      </DialogActions>
    </Dialog>
  );
}
