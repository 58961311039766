import {  connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import {ROLES} from "src/utils/setting"
import {  toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
// sections
import { UserListHead, UserListToolbar, UserForm } from 'src/sections/@dashboard/user';
 
function UserFormPage(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  let { id } = useParams(); 
 const handleBack = () => {
  navigate("/dashboard/user")
 } 
 useEffect(() => { 
}, []);
const getLisPagoda = (payload) => {
  props.getListPagoda({
    ...payload
    // page: page + 1,
    // limit: rowsPerPage,
    // order: order,
    // orderBy: orderBy,
    // keyword: filterName
  });
}
return (
    <>
    
      <Helmet>
        <title> Người quản lý </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" alignItems="center"  >
          <Iconify onClick={handleBack}  icon="openmoji:return" style={{width: "36px", cursor: "pointer"}} />
           <Typography variant="h4">{id ?  t("user_edit"):  t("user_create")}
          </Typography></Stack> 
        </Stack> 
       <UserForm t={t} listPagoda={props.listPagoda} getListPagoda={getLisPagoda} dataToken={props.dataToken}/>
      </Container>
     

    </>
  );
} 

const mapState = (state) => ({
  dataToken: state.auth.dataToken,
  listPagoda: state.pagoda.listPagoda,
});
const mapDispatch = (dispatch) => ({
  getListPagoda: dispatch.pagoda.getListPagoda
});
export default connect(mapState, mapDispatch)(UserFormPage)

