import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {  connect } from "react-redux";
import { useTranslation } from 'react-i18next';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography, Tabs, Tab  } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from 'src/components/logo';
// import Iconify from 'src/components/iconify';

// sections
import { LoginForm } from 'src/sections/auth/login';
import Language from 'src/components/language';
import LanguagePopover from "src/layouts/dashboard/header/LanguagePopover"
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function LoginPage(props) { 
  const mdUp = useResponsive('up', 'md');
  const [activeTab, setActiveTab] = useState(0);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    const currentLanguage = i18n.language;
    i18n.changeLanguage(lng);
}
  const currentLanguage = i18n.language;
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue)
  }
  return (
    <>
      <Helmet>
        <title> Đăng nhập  </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {mdUp && (
          <StyledSection>
            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back  
            </Typography> */}
            <img style={{
              height: "100%",
              objectFit: "cover"
            }} src="/assets/images/covers/jason-cooper-iEJVyyevw-U-unsplash.jpg" alt="login" />
          </StyledSection>
        )}
<Box sx={{ position: "fixed", right: 20, top: 20, width: 32, height: 32, borderRadius: 1.5, flexShrink: 0 }}> 
          {/* <LanguagePopover/> */}
          </Box>
        {/* <Box onClick={() => currentLanguage === "vn" ? changeLanguage("en") : changeLanguage("vn")} component="img" alt={"title"} src={currentLanguage === "vn" ? "assets/icons/us_flag.png" : "assets/icons/vn_flag.png"} sx={{ position: "fixed", right: 20, top: 20, width: 32, height: 32, borderRadius: 1.5, flexShrink: 0 }} /> */}
            <Container maxWidth="sm">
             
          <StyledContent>
          <Typography variant="h4" gutterBottom align="center">
              {t("sign_in")}
            </Typography>
          <Box sx={{ width: '100%', marginBottom: "30px" }}>
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
            {/* <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label="Super Admin"  sx={{ width: '50%' }}/>
              <Tab label="Admin chùa" sx={{ width: '50%' }} />
            </Tabs> */}
          {/* </Box> */}
        </Box>
           

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Get started</Link>
            </Typography> */}
{/* 
            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider> */}

            <LoginForm login={props.login} t={t}/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
const mapState = (state) => ({
  auth: state.auth,
});
const mapDispatch = (dispatch) => ({
  login: dispatch.auth.login
});
export default connect(mapState, mapDispatch)(LoginPage)
