 import {API_URL, LOGIN_TOKEN} from "src/utils/setting"
import axios from "axios";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { saveCookie, loadCookie } from "src/utils/cookies";
const users = {
    state: {
      listAdmin: [],
      total: 0,
    }, // initial state
    reducers: {
      setState(state, payload) {
        state = { ...state, ...payload };
        return { ...state };
      },
    },
    effects: (dispatch) => ({
      // handle state changes with impure functions.
      // use async/await for async actions
      async getListAdmin(payload, rootState) {
        try {
          let result = await axios.get(`${API_URL}admin/list?${createSearchParams(payload)}`, {
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          }); 
          this.setState({listAdmin: result?.data?.list})
          this.setState({total: result?.data?.count})
          return {
            code: 1,
            error: null,
            data: result?.data,
          }
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async createUser(payload, rootState) {
        try {
          let result = await axios.post(`${API_URL}admin`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async getDetailUser(payload, rootState) {
        try {
          let result = await axios.get(`${API_URL}admin/${payload.id}`, {
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async updateUser(payload, rootState) {
        delete(payload.password)
        delete(payload.pagoda)
        try {
          let result = await axios.put(`${API_URL}admin/${payload?.id}`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async deleteUser(payload, rootState) {
        try {
          let result = await axios.delete(`${API_URL}admin/${payload.id}`   ,{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },

      async updateProfile(payload, rootState) {
        try {
          let result = await axios.put(`${API_URL}admin/updateMyProfile`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async updatePassword(payload, rootState) {
        try {
          let result = await axios.put(`${API_URL}admin/updateMyPassword`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async updatePagoda(payload, rootState) {
        try {
          let result = await axios.put(`${API_URL}pagoda/updateMyPagoda`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
    }),
  };
  export  default users;