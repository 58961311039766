import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';

const Language = () => {
  const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const changeLanguage = (lng) => {
        const currentLanguage = i18n.language;
        i18n.changeLanguage(lng);
    }
  return (
    <Box onClick={() => currentLanguage === "vn" ? changeLanguage("en") : changeLanguage("vn")} component="img" alt={"title"} src={currentLanguage === "vn" ? "assets/icons/us_flag.png" : "assets/icons/vn_flag.png"} sx={{ position: "fixed", right: 20, top: 20, width: 32, height: 32, borderRadius: 1.5, flexShrink: 0 }} />
    )
}


export default Language