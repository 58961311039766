import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { ROLES } from "src/utils/setting"
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import AlertDialog from 'src/components/dialog';
// sections
import { UserListHead, UserListToolbar } from 'src/sections/@dashboard/user';
// mock
import USERLIST from 'src/_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 4),
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function UserForm(props) {
  const navigate = useNavigate();

  let { id } = useParams();

  const [user, setUser] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const { control, handleSubmit,  formState: { errors }, setValue, watch, getValues } = useForm();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  useEffect(() => {
    getDetailPagoda(id);
  }, [id]);
  const getDetailPagoda = async (id) => {
    if (id) {
      let resDetailUser = await props.getDetailPagoda({ id: id });
      if (resDetailUser?.code) {
        let data = resDetailUser.data
        Object.keys(data).forEach((name, index )=> { 
            setValue(name, Object.values(data)[index])
          });
          // setUser(resDetailUser.data)
      }
    }
  }
  const onChangeValue = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }
  const handleCreateUser = async (data) => {
    let resultCreate = await props.createPagoda(data);
    if (resultCreate?.code) {
      navigate("/dashboard/pagoda")
      toast("Tạo thành công")
    }
    else toast(resultCreate.message || resultCreate.error);
  }
  const handleUpdateUser = async (data) => {
    let resultUpdate = await props.updatePagoda(data);
    if (resultUpdate?.code) {
      navigate("/dashboard/pagoda")
      toast("Cập nhật thành công")
    }
    else toast(resultUpdate.message || resultUpdate.error);
  }

  const handleDeletePagoda = async () => {
    let resultDelete = await props.deletePagoda({ id: getValues()?.id });
    if (resultDelete?.code) {
      navigate("/dashboard/pagoda")
      toast("Xoá thành công")
    }
    else toast(resultDelete.message || resultDelete.error);
  }
  const handleBack = () => {
    navigate("/dashboard/pagoda")
  }

  const formUser = [
    {
      type: "text",
      label: props.t('user_pagoda_name'),
      name: "name",
      value: user?.name ,
    },
    {
      type: "text",
      label: props.t('user_pagoda_address'),
      name: "address",
      value: user?.address,
      required: true,
    },  
    {
      type: "text",
      label: props.t('user_pagoda_email'),
      name: "email",
      value: user?.email,
      required: false,
    },
    {
      type: "text",
      label: props.t('user_pagoda_website'),
      name: "website",
      value: user?.website,
      required: false,
    },
  ]
  return (
    <Card>
      <AlertDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        title={props.t("confirm")}
        textBtnFirst={props.t("agree")}
        textBtnSecond={props.t("disagree")}
        content={`${props.t("are_you_sure_want_to_delete")}  ${props.t("pagoda")} ${getValues()?.name}`}
        handleActionFirst={() => {
          setOpenDialog(false);
        }}
        handleActionSecond={handleDeletePagoda}
      />
      <StyledContent>
        <Stack spacing={3}>
          {formUser.map((e) => {
            if (e.hidden) return null
            return <>
            <Controller
                  name={e.name}
                  control={control}
                  rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
                  render={({ field }) => (
                    <TextField 
                      InputLabelProps={{ shrink: true }}
                      type={e.type}
                      required={e.required}
                      label={e.label} 
                      error={Boolean(errors[e.name])} // Show error state if validation fails
                      helperText={errors[e.name]?.message} // Display error message
                      {...field} 
                    />
                  )}
                /> 
            </> 
              // return <TextField
              //   id="outlined-basic"
              //   label={e.label}
              //   InputProps={{
              //     readOnly: e.disable ? true : false,
              //   }}
              //   InputLabelProps={{ shrink: true }}
              //   variant="outlined"
              //   value={e.value}
              //   type={e.type}
              //   name={e.name}
              //   required={e.required}
              //   onChange={onChangeValue}
              // />
          })} 
        </Stack>
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="right" style={{ marginTop: '24px' }}>
          {id ? <Button variant="contained" onClick={handleSubmit(handleUpdateUser)} >
          Cập nhật
          </Button>
            : <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleSubmit(handleCreateUser)} >
              Tạo
            </Button>}
          {id ? <Button variant="contained" color="error" startIcon={<Iconify icon="material-symbols:delete" />} onClick={() => setOpenDialog(true)}  >
            Xoá
          </Button> : null}
        </Stack>
      </StyledContent>
    </Card>
  );
}

const mapState = (state) => ({
  listPagoda: state.pagoda.listPagoda,
});
const mapDispatch = (dispatch) => ({
  getListPagoda: dispatch.pagoda.getListPagoda,
  getDetailPagoda: dispatch.pagoda.getDetailPagoda,
  createPagoda: dispatch.pagoda.createPagoda,
  updatePagoda: dispatch.pagoda.updatePagoda,
  deletePagoda: dispatch.pagoda.deletePagoda,
});
export default connect(mapState, mapDispatch)(UserForm)

