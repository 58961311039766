import * as React from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ROLES } from "src/utils/setting"
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import Upload from 'src/components/upload'
// @mui 
import { styled } from '@mui/material/styles';
import {
    Box,
    Tabs,
    Tab,
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Grid,
    TextField, 
} from '@mui/material'; 
const StyledContent = styled('div')(({ theme }) => ({
    //   maxWidth: 480,
    //   margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4, 4),
    backgroundColor: "#fff",
    borderRadius: "4px",
}));
function PaagodaForm(props) {
    const pagodaForm = useForm();
    useEffect(() => {
        pagodaForm.reset()
        setValueDataPagoda()
        getPagodaIndex()
    }, [props.dataToken]);
    const setValueDataPagoda = async() => {  
        pagodaForm.setValue("name", props.dataToken?.pagoda?.name)
        pagodaForm.setValue("address", props.dataToken?.pagoda?.address)
        pagodaForm.setValue("email", props.dataToken?.pagoda?.email)
        pagodaForm.setValue("website", props.dataToken?.pagoda?.website)
        pagodaForm.setValue("image", props.dataToken?.pagoda?.image)
        // pagodaForm.setValue("pagoda_index.prefix", props.dataToken?.pagoda?.pagoda_index?.prefix)
        // pagodaForm.setValue("pagoda_index.index", props.dataToken?.pagoda?.pagoda_index?.index)
    } 
    const getPagodaIndex = async() => {
        let pagodaIndex = await props.getPagodaIndex();
        if(pagodaIndex?.code === 1) {
            pagodaForm.setValue("pagoda_index.prefix", pagodaIndex?.data?.prefix)
            pagodaForm.setValue("pagoda_index.index", pagodaIndex?.data?.index)
        } 
    }
    const formPagoda = [
        {
          type: "text",
          label: props.t('user_pagoda_name'),
          name: "name",
          required: true,
        },    {
          type: "text",
          label: props.t('user_pagoda_address'),
          name: "address",
          required: true,
        },  {
          type: "text",
          label: props.t('user_pagoda_email'),
          name: "email",
          required: false,
        }, {
          type: "text",
          label: props.t('user_pagoda_website'),
          name: "website",
          required: false,
        },
        {
            type: "image",
            label: props.t('user_avatar'),
            name: "image",
        },
        {
            type: "section",
            label: props.t('number_postion'),
        },
        {
            type: "text",
            label: props.t('user_pagoda_prefix'),
            name: "pagoda_index.prefix",
            required: false,
        },
        {
            type: "text",
            label: props.t('user_pagoda_index'),
            name: "pagoda_index.index",
            required: false,
        },
         ]
    return (
        <StyledContent>
            <Paper
                style={{
                    display: "grid",
                    gridRowGap: "20px"
                }}
            >
                {formPagoda.map((e) => {
                    if (e.hidden) return null;
                    if (e.type === "section") return <h4 style={{ marginBottom: "16px" }}>{e.label}</h4>
                    if(e.type === "image") return   <Controller
                    name={e.name}
                    control={pagodaForm.control}
                    rules={{ required: e.required ? `${e.label} ${props.t('is_required')}` : '' }} // Add required rule
                    render={({ field }) => (
                        <Upload
                            label={e.label}
                            previewImgs={pagodaForm.getValues()?.[e.name] ? [pagodaForm.getValues()?.[e.name]] : []}
                            uploadOnlyOneFile={true}
                            importUrl={false}
                            contentUploadButton={props.t('click_to_upload')}
                            uploadFile={(file) => pagodaForm.setValue(e.name, file)}
                            deleteFile={(file) => pagodaForm.setValue(e.name, file)}
                        /> 
                    )}
                />
                    return <>
                        <Controller
                            name={e.name}
                            control={pagodaForm.control}
                            rules={{ required: e.required ? `${e.label} ${props.t('is_required')}` : '' }} // Add required rule
                            render={({ field }) => (
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type={e.type}
                                    disabled={e.disabled}
                                    required={e.required}
                                    label={e.label}
                                    error={Boolean(pagodaForm?.formState?.errors?.[e.name])} // Show error state if validation fails
                                    helperText={pagodaForm?.formState?.errors?.[e.name]?.message} // Display error message
                                    { ...field}
                                />
                            )}
                        />
                    </>
                })}
            </Paper>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="right" style={{ marginTop: '24px' }}>
                <Button variant="contained" disabled={pagodaForm?.formState?.isSubmitting} onClick={pagodaForm.handleSubmit(props.handleUpdateUser)}>
               Cập nhật
                </Button>
            </Stack>
        </StyledContent>
    );
}

export default (PaagodaForm)

