import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import vi from "./vi"
import en from "./en"
i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations:  en
      },
      vi: {
        translations:  vi
      }
    },
    lng: 'vi',
    fallbackLng: 'vi',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true
    }
  });

export default i18n;