import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Calendar from 'react-calendar';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));
export default function SendMessageDialog({
    open = false, 
    handleClose, 
    title, 
    content,
    handleActionFirst,
    handleActionSecond,
    textBtnFirst, 
    textBtnSecond, 
    onChange,
    name,
    value,
    handleSend
}) {
  const { control, handleSubmit,  formState: { errors }, setValue, watch, getValues } = useForm();
  const formUser = [
    {
      type: "text",
      label: "Tựa đề",
      name: "title",
    },
    {
      type: "text",
      label: "Nội dung",
      name: "description",
    }
  ]
  if(new Date(value).toString() == 'Invalid Date') value = new Date()
  return (
    <Dialog
    open={open}
    // onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
    Gửi thông báo 
    </DialogTitle>
    <DialogContent>
    <StyledContent>
        <Stack spacing={3}>
    {formUser.map((e) => {
            if (e.hidden) return null
            return <>
            <Controller
                  name={e.name}
                  control={control}
                  // rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
                  render={({ field }) => (
                    <TextField 
                      InputLabelProps={{ shrink: true }}
                      type={e.type}
                      required={e.required}
                      label={e.label} 
                      error={Boolean(errors[e.name])} // Show error state if validation fails
                      helperText={errors[e.name]?.message} // Display error message
                      {...field} 
                    />
                  )}
                /> 
            </>  
          })} 
           </Stack>
      </StyledContent>
    </DialogContent>
    <DialogActions>
    <Button onClick={() => {
      handleClose()
      }} autoFocus  color="primary">
        {"Đóng"}
      </Button>
    <Button onClick={handleSubmit(handleSend)} autoFocus  color="error">
        {"Gửi"}
      </Button>
      {/* <Button onClick={handleClose}>Chọn</Button> */}
    </DialogActions>
    {/* <DialogActions>
    <Button onClick={handleActionSecond} autoFocus  variant="contained" color="error">
        Delete
      </Button>
      <Button onClick={handleActionFirst}  variant="outlined"  color="secondary">Cancel</Button>
    </DialogActions> */}
  </Dialog>
  );
}
