import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Calendar from 'react-calendar';

export default function CalendarDialog({
    open = false, 
    handleClose, 
    title, 
    content,
    handleActionFirst,
    handleActionSecond,
    textBtnFirst, 
    textBtnSecond, 
    onChange,
    name,
    value,
    tileContent
}) {
  if(new Date(value).toString() == 'Invalid Date') value = new Date()
  return (
    <Dialog
    open={open}
    // onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
    {title}
    </DialogTitle>
    <DialogContent>
    <Calendar 
                value={value}
                onChange={(e) => {
                  handleClose()
                  onChange(e)}} 
                  tileContent={tileContent}  
                  locale="vi"
                />
    </DialogContent>
    <DialogActions>
    <Button onClick={() => {
      handleClose()
      }} autoFocus  color="primary">
        {"Đóng"}
      </Button>
    <Button onClick={() => {
      onChange(null)
      handleClose()
      }} autoFocus  color="error">
        {"Xoá ngày"}
      </Button>
      {/* <Button onClick={handleClose}>Chọn</Button> */}
    </DialogActions>
    {/* <DialogActions>
    <Button onClick={handleActionSecond} autoFocus  variant="contained" color="error">
        Delete
      </Button>
      <Button onClick={handleActionFirst}  variant="outlined"  color="secondary">Cancel</Button>
    </DialogActions> */}
  </Dialog>
  );
}
