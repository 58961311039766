import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import UserFormPage from './pages/UserFormPage';
import PagodaPage from './pages/PagodaPage';
import PagodaFormPage from './pages/PagodaFormPage';
import AshesPage from './pages/AshesPage';
import AshesFormPage from './pages/AshesFormPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ProfilePage from './pages/ProfilePage';
import FormPage from './pages/FormPage';
 
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/ashes" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user/create', element: <UserFormPage /> },
        { path: 'user/:id', element: <UserFormPage/> },
        { path: 'user', element: <UserPage /> },
        { path: 'pagoda/create', element: <PagodaFormPage /> },
        { path: 'pagoda/:id', element: <PagodaFormPage/> },
        { path: 'pagoda', element: <PagodaPage /> },
        { path: 'ashes/create', element: <AshesFormPage /> },
        { path: 'ashes/:id', element: <AshesFormPage/> },
        { path: 'ashes', element: <AshesPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'form/:id', element: <FormPage /> },
        { path: 'form', element: <FormPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/user" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
