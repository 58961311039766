import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider  } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import store from "./store";
// ----------------------------------------------------------------------
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './language/i18n';
import 'src/assets/css/index.css'
export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <I18nextProvider i18n={ i18n }> 
    <Provider store={store}> 
    <HelmetProvider >
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <ToastContainer />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
    </Provider>
    </I18nextProvider>
    </LocalizationProvider>
  );
}
