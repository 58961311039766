import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {  connect } from "react-redux";
import { useTranslation } from 'react-i18next';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';

import { LOGIN_TOKEN} from "src/utils/setting"
import {   loadCookie } from "src/utils/cookies";
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

function DashboardLayout(props) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [dataToken, setDataToken] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
      const tokenLogin = loadCookie(LOGIN_TOKEN)
      if(!tokenLogin) navigate("/login");
      else verifyToken()
  }, [navigate]);
  const verifyToken = async() => {
    let resultVerifyToken = await props.verifyToken()
  let newdataToken = {
    ...resultVerifyToken?.data
  }
  
  if(resultVerifyToken?.code === 1) setDataToken(newdataToken)
    else navigate("/login");
  }
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} logout={props.logout} account={dataToken}/>

      <Nav openNav={open} onCloseNav={() => setOpen(false)} account={dataToken} t={t}/>

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
const mapState = (state) => ({
});
const mapDispatch = (dispatch) => ({
  verifyToken: dispatch.auth.verifyToken,
  logout: dispatch.auth.logout
});
export default connect(mapState, mapDispatch)(DashboardLayout)