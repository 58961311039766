import { format, getTime, formatDistanceToNow } from 'date-fns';
import viLocale from "date-fns/locale/vi";
import { Lunar, BlockLunarDate } from 'lunar-calendar-ts-vi';
// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy p';

    return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}

export function formatDateTime(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy HH:mm:ss';

    return date ? format(new Date(date), fm, { locale: viLocale }) : '';
}
export function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const options = {
        timeZone: "Asia/Ho_Chi_Minh",
        timeZoneName: "short"
    };
    const day = date.getDate();
    const month = date.getMonth() + 1; // Tháng trong JavaScript được đánh số từ 0 đến 11, nên cần cộng thêm 1.
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return {
        day,month,year,hours,minutes
    }
}
export function formatTimestamp(timestamp) {
    if(timestamp) {
         const d = convertTimestampToDate(timestamp)
    
        // Đảm bảo rằng các giá trị ngày, tháng, giờ, phút có hai chữ số bằng cách thêm '0' khi cần thiết.
        const formattedDay = String(d.day).padStart(2, '0');
        const formattedMonth = String(d.month).padStart(2, '0');
        const formattedHours = String(d.hours).padStart(2, '0');
        const formattedMinutes = String(d.minutes).padStart(2, '0');
    
        const formattedDate = `${formattedDay}/${formattedMonth}/${d.year}`;
        const formattedTime = `${formattedHours}:${formattedMinutes}`;
    
        return `${formattedDate}`;
    }
    return null
}

export function formatTimestampToLunar(timestamp) {
    const d = convertTimestampToDate(timestamp) 
    if(d?.day && d?.month && d?.year) {
        const lunar2 = new Lunar();
        let lunarDateConvert = lunar2.getBlockLunarDate(`${d.year}/${d.month}/${d.day}`);
        console.log(lunarDateConvert)
        return `${lunarDateConvert.lunarDate}/${lunarDateConvert.lunarMonth}/${lunarDateConvert.lunarYear} (Năm ${lunarDateConvert.lunarYearStr})`
    }
    return "Chưa xác định"
     
}