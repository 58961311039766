export const API_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4000/core/"
export const LOGIN_TOKEN =  "LOGIN_TOKEN";
export const ROLES = {
    SUPER_ADMIN_ROLE:"super-admin" ,
    ADMIN_ROLE:"admin",
    MANAGER_ROLE:"manager",  
    USER_ROLE:"user",
    ANONYMOUS_ROLE:"anonymous" 
}
export function convertDaysToYearsMonthsDays(days) {
    days= Math.abs(parseInt(days));
    const years = Math.floor(days / 365);
    const remainingDays = days % 365;
    const months = Math.floor(remainingDays / 30);
    const daysLeft = remainingDays % 30;
  
    return {
      years: years,
      months: months,
      days: daysLeft
    };
  }
export function convertDays(days) {
    let convertObject = convertDaysToYearsMonthsDays(days)
    if(days == 0) return "Đã đến hạn"
    if(!days) return ""
    if(days >= 0) return  `Quá hạn ${convertObject?.years ? `${convertObject?.years} năm`: ''} ${convertObject?.months ? `${convertObject?.months} tháng`: ''}  ${convertObject?.days ? `${convertObject?.days} ngày`: ''}`
    else {
        return  `Còn ${convertObject?.years ? `${convertObject?.years} năm`: ''} ${convertObject?.months ? `${convertObject?.months} tháng`: ''}  ${convertObject?.days ? `${convertObject?.days} ngày`: ''}`
    }
}
