 import {API_URL, LOGIN_TOKEN} from "src/utils/setting"
import axios from "axios";
import { saveCookie, loadCookie } from "src/utils/cookies";
const auth = {
    state: {
      dataToken: null
    }, // initial state
    reducers: {
      setState(state, payload) {
        state = { ...state, ...payload };
        return { ...state };
      },
    },
    effects: (dispatch) => ({
      // handle state changes with impure functions.
      // use async/await for async actions
      async login(payload, rootState) {
        try {
          let response = await axios.post(`${API_URL}auth/login`, {
            username: payload.username,
            password: payload.password,
          }); 
          let result = response.data
          if(result?.code === 1) {
            saveCookie({
              name: LOGIN_TOKEN,
              data: result.data?.access_token,
            });
          }
          return result
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error,
            message: error.message,
          }
        }
         
      },
      async refreshToken(payload, rootState) {
        try {
          let result = await axios.get(`${API_URL}auth/refreshToken`,{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          })
          saveCookie({
            name: LOGIN_TOKEN,
            data: result.data?.access_token,
          });
          this.setState({dataToken: result?.data || null})
          return {
            code: 1,
            error: null,
            data: result?.data,
          }
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      logout(payload, rootState) {
        dispatch({ type: 'RESET_APP' });
      },
      async verifyToken(payload, rootState) {
        try {
          let result = await axios.get(`${API_URL}verify-token`,{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          }); 
          this.setState({dataToken: result?.data || null})
          return {
            code: 1,
            error: null,
            data: result?.data,
          }
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      
       
    }),
  };
  export  default auth;