 
import {API_URL, LOGIN_TOKEN} from "src/utils/setting"
import axios from "axios";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { saveCookie, loadCookie } from "src/utils/cookies";
const upload = {
    state: {
      listAdmin: [],
      total: 0,
    }, // initial state
    reducers: {
      setState(state, payload) {
        state = { ...state, ...payload };
        return { ...state };
      },
    },
    effects: (dispatch) => ({
       async uploadFile(payload, rootState) {
            try {
            if (payload.files && payload.files.length) {
                const formData = new FormData();
                payload.files.forEach((file) => {
                  if(file) formData.append("image", file);
                });
                if(payload.path) formData.append("path", payload.path);
                let response = await axios.post(`${API_URL}upload-aws/image`, formData, {
                headers: {
                    Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`,
                    "Content-type": "multipart/form-data",
                }
                }); 
                return response.data;
            }
            } catch(error) {
              return {
                code: 0,
                error: error,
                message: error.message
              };
            }
            
        },
        async uploadFiles(payload, rootState) {
          try {
          if (payload.files && payload.files.length) {
              const formData = new FormData();
              payload.files.forEach((file) => {
                formData.append("image", file);
              });
              if(payload.path) formData.append("path", payload.path);
              let response = await axios.post(`${API_URL}upload-aws/images`, formData, {
              headers: {
                  Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`,
                  "Content-type": "multipart/form-data",
              }
              }); 
              return response.data;
          }
          } catch(error) {
            return {
              code: 0,
              error: error,
              message: error.message
            };
          }
          
      },
      async deleteFiles(payload, rootState) {
        try {
        if (payload.image_url_array) { 
            let response = await axios.post(`${API_URL}upload-aws/delete-images`, {image_url_array: payload.image_url_array}, {
            headers: {
                Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`,
            }
            }); 
            return response.data;
        }
        } catch(error) {
          return {
            code: 0,
            error: error,
            message: error.message
          };
        }
        
    },
       
    }),
  };
  export  default upload;