import {  connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from 'src/sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import { ROLES } from "src/utils/setting";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

 

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function UserPage(props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const moreThanSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const TABLE_HEAD = !moreThanSmall ? 
  [
    { id: 'username', label: t('user_username'), alignRight: false },
    { id: 'pagoda.name', label: t('user_pagoda_name'), alignRight: false },
    { id: '' },
  ] : [
    { id: 'name', label: t('user_name'), alignRight: false },
    { id: 'email', label: t('user_email'), alignRight: false },
    // { id: 'role', label: 'Role', alignRight: false },
    { id: 'username', label: t('user_username'), alignRight: false },
    { id: 'pagoda.name', label: t('user_pagoda_name'), alignRight: false },
    { id: 'pagoda.address', label: t('user_pagoda_address'), alignRight: false },
    { id: '' },
  ];

  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const [itemSelected, setItemSelected] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('created');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setItemSelected(id)
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setItemSelected(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.listAdmin.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleEditUser = () => {
    navigate(`/dashboard/user/${itemSelected}`)
  }
  const emptyRows = page > 0 ? rowsPerPage - props.listAdmin.length : 0;

  // const filteredUsers = applySortFilter(props.listAdmin, getComparator(order, orderBy), filterName);

  // const isNotFound = !filteredUsers.length && !!filterName;
  useEffect(() => { 
    props.getListAdmin({
      page: page + 1,
      limit: rowsPerPage,
      order: order,
      orderBy: orderBy,
      keyword: filterName
    });
  }, [rowsPerPage, page, order, orderBy, filterName]);
//   useEffect(() => {
//     const tokenLogin = loadCookie(LOGIN_TOKEN)
//     if(!tokenLogin) navigate("/login");
//     else verifyToken()
// }, [navigate]);
  const handleRedirectUserForm = () => {
    navigate("/dashboard/user/create")
  }
  return (
    <>
      <Helmet>
        <title>Người quản lý</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t("user")}
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>} onClick={handleRedirectUserForm}>
            {t("add_new")}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} t={t} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: "100%" }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={props.listAdmin.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  hideCheckBox={true}
                />
                <TableBody>
                  {props.listAdmin.map((row) => {
                    const { id, email, name, role_code,  username, pagoda } = row;
                    const selectedUser = selected.indexOf(id) !== -1;
                    if(role_code === ROLES.ADMIN_ROLE)
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell> */}

                        {moreThanSmall && <TableCell component="th" scope="row" padding="16">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={""} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>}

                       
                        {moreThanSmall && <TableCell align="left">{email}</TableCell>}
                        {/* <TableCell align="left">{role_code}</TableCell> */}
                        <TableCell align="left">{username}</TableCell>
                        <TableCell align="left">{pagoda?.name}</TableCell>
                        {moreThanSmall && <TableCell align="left">{pagoda?.address}</TableCell>}


                        {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 80 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {/* {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={props.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditUser}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Chỉnh sửa
        </MenuItem>

        {/* <MenuItem  onClick={handleDeleteUser} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </>
  );
}

const mapState = (state) => ({
  listAdmin: state.users.listAdmin,
  total: state.users.total,
  dataToken: state.auth.dataToken
});
const mapDispatch = (dispatch) => ({
  getListAdmin: dispatch.users.getListAdmin
});
export default connect(mapState, mapDispatch)(UserPage)

