import { init } from "@rematch/core";
import * as models from "./models";

const store = init({ models,
    redux: {
        rootReducers: {
          RESET_APP: (state, action) => undefined,
        },
        // devtoolOptions: {
        //   disabled: process.env.NODE_ENV === 'production',
        // },
      },
 });

export default store;