 import {API_URL, LOGIN_TOKEN} from "src/utils/setting"
import axios from "axios";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { saveCookie, loadCookie } from "src/utils/cookies";
const ashes = {
    state: {
      listAshes: [],
      total: 0,
      currentPage: 1,
      currentLimit: 10,
    }, // initial state
    reducers: {
      setState(state, payload) {
        state = { ...state, ...payload };
        return { ...state };
      },
    },
    effects: (dispatch) => ({
      // handle state changes with impure functions.
      // use async/await for async actions
      async getListAshes(payload, rootState) {
        try {
          let result = await axios.get(`${API_URL}ashes/list?${createSearchParams(payload)}`, {
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          }); 
          this.setState({listAshes: result?.data?.list || []})
          this.setState({total: result?.data?.count})
          return {
            code: 1,
            error: null,
            data: result?.data,
          }
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async createAshes(payload, rootState) {
        try {
          let result = await axios.post(`${API_URL}ashes`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async sendMessage(payload, rootState) {
        try {
          let result = await axios.post(`${API_URL}ashes/sendMessage`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async getDetailAshes(payload, rootState) {
        try {
          let result = await axios.get(`${API_URL}ashes/${payload.id}`, {
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async updateAshes(payload, rootState) {
        try {
          let result = await axios.put(`${API_URL}ashes/${payload?.id}`, {
            ...payload
          },{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      },
      async deleteAshes(payload, rootState) {
        try {
          let result = await axios.delete(`${API_URL}ashes/${payload.id}`   ,{
            headers: {
              Authorization: `Bearer ${loadCookie(LOGIN_TOKEN)}`
            }
          });  
          return result.data;
        } catch(error) {
          return {
            code: 0,
            data: null,
            error: error.message
          }
        }
         
      }, 
    }),
  };
  export  default ashes;