import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, TextField } from '@mui/material';
// component
import Iconify from '../../../components/iconify';
import Autocomplete from '@mui/material/Autocomplete';
import { ROLES } from "src/utils/setting";
import FilterDialog from 'src/components/filterDialog';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

AshesListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function AshesListToolbar({ numSelected, filterName, onFilterName, t, options = [], valueFilterPagoda, onFilterPagoda, getListPagoda, dataToken, onFilterAshesList }) {
  const [openFilter, setOpenFilter] = useState(false);
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <FilterDialog
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        handleActionFirst={(e) => { setOpenFilter(false); onFilterAshesList(e) }}
        handleActionSecond={(e) => { setOpenFilter(false); onFilterAshesList(e) }}
        textBtnFirst="Tắt bộ lọc"
        textBtnSecond="Lọc danh sách"
        getListPagoda={getListPagoda}
        valueFilterPagoda={valueFilterPagoda}
        options={options}
        dataToken={dataToken}
      />
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder={t("search")}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}
      {dataToken?.role_code === ROLES.SUPER_ADMIN_ROLE &&
        <Autocomplete
          options={[...options]}
          getOptionLabel={(option) => option.name}
          value={options.find(e => e.id === valueFilterPagoda) || null}
          renderOption={(props, option) => { return <li {...props}>{`${option.name}-${option.address}`}</li> }}
          onChange={(event, newValue) => {
            onFilterPagoda(newValue?.id);
          }}
          onInputChange={(event, value, reason) => {
            if (value != "undefined" && value) {
              getListPagoda({
                keyword: value
              })
            }
          }}
          renderInput={(params) => <TextField {...params}
            style={{ width: "200px" }}
            // onChange={(e) => {
            //   props.getListPagoda({
            //     keyword: e.target.value
            //   })
            // }}
            label={t('user_pagoda_name')}
          />}
          label="Select an option"
        />
      }
      {dataToken?.role_code === ROLES.ADMIN_ROLE &&
        <Tooltip title="Filter list">
          <IconButton onClick={() => setOpenFilter(true)}>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      }
    </StyledRoot>
  );
}
