import * as React from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { ROLES } from "src/utils/setting"
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
// @mui 
import { styled } from '@mui/material/styles';
import {
    Box,
    Tabs,
    Tab,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormLabel,
  CardMedia,
  InputAdornment
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import AlertDialog from 'src/components/dialog';
// sections
import { UserListHead, UserListToolbar } from 'src/sections/@dashboard/user';
// mock
import USERLIST from 'src/_mock/user';
import UserForm from './UserForm'
import PagodaForm from './PagodaForm'
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: '' },
];
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
// ----------------------------------------------------------------------
const StyledContent = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 4),
  backgroundColor: "#fff",
  borderRadius:  "4px",
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function ProfileForm(props) {
  const navigate = useNavigate();

  let { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
//   const [showNewPassword, setShowNewPassword] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const [user, setUser] = useState(null);


  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const userForm = useForm();
  const passwordForm = useForm();
  const pagodaForm = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const options = (props.listPagoda || []) 
  const [valueSelect, setValueSelect] = useState();
  const [inputValueSelect, setInputValueSelect] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };


  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  useEffect(() => {
    userForm.reset()
    pagodaForm.reset()
    if(activeTab === 0) setValueDataUser()
    if(activeTab === 2) setValueDataPagoda()
    // setValue("username", props.dataToken?.username)
  }, [props.dataToken, activeTab]);
  const setValueDataUser = async() => {
    userForm.setValue("name", props.dataToken?.name)
    userForm.setValue("username", props.dataToken?.username)
    userForm.setValue("email", props.dataToken?.email)
  } 
  const setValueDataPagoda = async() => {  
    pagodaForm.setValue("name", props.dataToken?.pagoda?.name)
    pagodaForm.setValue("address", props.dataToken?.pagoda?.address)
    pagodaForm.setValue("email", props.dataToken?.pagoda?.email)
    pagodaForm.setValue("website", props.dataToken?.pagoda?.website)
  } 
  const onChangeValue = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }
  const handleCreateUser = async (data) => {
    let resultCreate = await props.createUser({
      ...data,
      pagoda_id: data?.pagoda_id?.id || data?.pagoda_id || null,
      pagoda: null
    });
    if (resultCreate?.code) {
      navigate("/dashboard/user")
      toast("Tạo thành công")
    }
    else toast(resultCreate.message || resultCreate.error);
  }
  const handleUpdateUser = async (data) => {
    let resultUpdate 
    if(activeTab === 0) {
        if(data?.image?.data) {
            let resUpload = await props.uploadFile({
                files: [data?.image],
                path: "qltc"
            });
            data.image = resUpload?.code === 1 ? resUpload.data : null
        }
        resultUpdate = await props.updateUser({
            ...data,
            pagoda_id: data?.pagoda_id?.id || data?.pagoda_id || null,
            pagoda: null,
            password: null,
        });
    } else if(activeTab === 1) {
        resultUpdate = await props.updatePassword({
            ...data, 
        });
    } else if (activeTab === 2) {
        if(data?.image?.data) {
            let resUpload = await props.uploadFile({
                files: [data?.image],
                path: "qltc"
            });
            data.image = resUpload?.code === 1 ? resUpload.data : null
        }
        resultUpdate = await props.updatePagoda({
            ...data, 
        });
    }
   
    if (resultUpdate?.code) {
        await props.refreshToken()
        props.verifyToken()
      toast("Cập nhật thành công")
    }
    else toast(resultUpdate.message || resultUpdate.error);
  }  
const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };   
    const formUpdatePassword = [
        {
          type: "password",
          label: props.t('user_old_password'),
          name: "old_password",
          required: true,
        },    {
          type: "password",
          label: props.t('user_new_password'),
          name: "new_password",
          required: true,
        }]  
    const formPagoda = [
        {
          type: "text",
          label: props.t('user_pagoda_name'),
          name: "name",
          required: true,
        },    {
          type: "text",
          label: props.t('user_pagoda_address'),
          name: "address",
          required: true,
        },  {
          type: "text",
          label: props.t('user_pagoda_email'),
          name: "email",
          required: false,
        }, {
          type: "text",
          label: props.t('user_pagoda_website'),
          name: "website",
          required: false,
        }]
  return (
    <>
 <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example" scrollButtons="auto" variant="scrollable">
          <Tab label="Thông tin cá nhân" {...a11yProps(0)} />
          <Tab label="Cập nhật mật khẩu" {...a11yProps(0)} />
          {props.dataToken?.role_code === ROLES.ADMIN_ROLE && <Tab label="Thông tin chùa" {...a11yProps(1)} />}
        </Tabs>
      </Box>
 
      {activeTab === 0 ? 
      <UserForm
        dataToken={props.dataToken}
        handleUpdateUser={handleUpdateUser}
        t={props.t}
      />
    
      :  activeTab === 1 ?
        <StyledContent>  
            <Paper
        style={{
          display: "grid",
          gridRowGap: "20px", 
        }}
      >
        {formUpdatePassword.map((e) => {
            if (e.hidden) return null; 
            if(e.type === "password") return   <Controller
            name={e.name}
            control={passwordForm.control}
            rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
            render={({ field }) => (
              <TextField 
                InputLabelProps={{ shrink: true }}
                type={showPassword ? 'text' : 'password'}
                disabled={e.disabled}
                required={e.required}
                label={e.label} 
                error={Boolean(passwordForm?.formState?.errors?.[e.name])} // Show error state if validation fails
                helperText={passwordForm?.formState?.errors?.[e.name]?.message} // Display error message
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                {...field} 
              />
            )}
          /> 
            return <>
            <Controller
                  name={e.name}
                  control={passwordForm}
                  rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
                  render={({ field }) => (
                    <TextField 
                      InputLabelProps={{ shrink: true }}
                      type={e.type}
                      disabled={e.disabled}
                      required={e.required}
                      label={e.label} 
                      error={Boolean(passwordForm?.formState?.errors?.[e.name])} // Show error state if validation fails
                      helperText={passwordForm?.formState?.errors?.[e.name]?.message} // Display error message
                      {...field} 
                    />
                  )}
                /> 
            </> 
          })} 
          </Paper>
          <Stack direction="row" spacing={3} alignItems="center" justifyContent="right" style={{ marginTop: '24px' }}>
            <Button variant="contained"  disabled={passwordForm?.formState?.isSubmitting} onClick={passwordForm.handleSubmit(handleUpdateUser)}>
                 Cập nhật
                </Button>
          </Stack>
        </StyledContent>
             : activeTab === 2 ?
             <PagodaForm
             dataToken={props.dataToken}
             handleUpdateUser={handleUpdateUser}
             getPagodaIndex={props.getPagodaIndex}
             t={props.t}
             />
      :    null
        }  
    </>
  );
}

const mapState = (state) => ({
  dataToken: state.auth.dataToken, 
});
const mapDispatch = (dispatch) => ({
    updateUser: dispatch.users.updateProfile,
    updatePassword: dispatch.users.updatePassword,
    updatePagoda: dispatch.users.updatePagoda,
    refreshToken: dispatch.auth.refreshToken,
    verifyToken: dispatch.auth.verifyToken,
    uploadFile: dispatch.upload.uploadFile,
    getPagodaIndex: dispatch.pagoda.getPagodaIndex,
});
export default connect(mapState, mapDispatch)(ProfileForm)

