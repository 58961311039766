import {  connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import {ROLES} from "src/utils/setting"
import {  toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
// sections
import { PagodaHead, PagodaToolbar, PagodaForm } from 'src/sections/@dashboard/pagoda';
 
function UserFormPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
 
  let { id } = useParams(); 
 const handleBack = () => {
  navigate("/dashboard/pagoda")
 } 
 
  return (
    <>
    
      <Helmet>
        <title> Chùa </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" alignItems="center"  >
          <Iconify onClick={handleBack}  icon="openmoji:return" style={{width: "36px", cursor: "pointer"}} />
           <Typography variant="h4">{id ?  t("pagoda_edit"):  t("pagoda_create")}
          </Typography></Stack> 
        </Stack> 
       <PagodaForm t={t}/>
      </Container>
     

    </>
  );
} 

export default UserFormPage

