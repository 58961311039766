import * as React from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ROLES } from "src/utils/setting"
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import Upload from 'src/components/upload'
// @mui 
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Box,
    Tabs,
    Tab,
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Grid,
    TextField, 
} from '@mui/material'; 
const StyledContent = styled('div')(({ theme }) => ({
    //   maxWidth: 480,
    //   margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4, 4),
    backgroundColor: "#fff",
    borderRadius: "4px",
}));
function UserForm(props) {
    const userForm = useForm();
    useEffect(() => {
        userForm.reset()
        setValueDataUser()
    }, [props.dataToken]);
    const setValueDataUser = async () => {
        userForm.setValue("name", props.dataToken?.name)
        userForm.setValue("username", props.dataToken?.username)
        userForm.setValue("email", props.dataToken?.email)
        userForm.setValue("image", props.dataToken?.image)
    }
    const formUser = [
        {
            type: "text",
            label: props.t('user_username'),
            name: "username",
            required: true,
            disabled: true,
        }, {
            type: "text",
            label: props.t('user_name'),
            name: "name",
        },
        {
            type: "image",
            label: props.t('user_avatar'),
            name: "image",
        }]
    return (
        <StyledContent>
            <Paper
                style={{
                    display: "grid",
                    gridRowGap: "20px"
                }}
            >
                {formUser.map((e) => {
                    if (e.hidden) return null;
                    if(e.type === "image") return   <Controller
                    name={e.name}
                    control={userForm.control}
                    rules={{ required: e.required ? `${e.label} ${props.t('is_required')}` : '' }} // Add required rule
                    render={({ field }) => (
                        <Upload
                            label={e.label}
                            previewImgs={userForm.getValues()?.[e.name] ? [userForm.getValues()?.[e.name]] : []}
                            uploadOnlyOneFile={true}
                            importUrl={false}
                            contentUploadButton={props.t('click_to_upload')}
                            uploadFile={(file) => userForm.setValue(e.name, file)}
                            deleteFile={(file) => userForm.setValue(e.name, file)}
                        /> 
                    )}
                />
                    return <>
                        <Controller
                            name={e.name}
                            control={userForm.control}
                            rules={{ required: e.required ? `${e.label} ${props.t('is_required')}` : '' }} // Add required rule
                            render={({ field }) => (
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type={e.type}
                                    disabled={e.disabled}
                                    required={e.required}
                                    label={e.label}
                                    error={Boolean(userForm?.formState?.errors?.[e.name])} // Show error state if validation fails
                                    helperText={userForm?.formState?.errors?.[e.name]?.message} // Display error message
                                    {...field}
                                />
                            )}
                        />
                    </>
                })}
                
            </Paper>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="right" style={{ marginTop: '24px' }}>
                <Button variant="contained" disabled={userForm?.formState?.isSubmitting}   onClick={userForm.handleSubmit(props.handleUpdateUser)}>
                    Cập nhật
                </Button>
            </Stack>
        </StyledContent>
    );
}

export default (UserForm)

