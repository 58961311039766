import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet-async';
import {  connect } from "react-redux";
import FormPrint from 'src/components/form';
import { useParams } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    Stack,
    Container,
    Typography,
    Button
  } from '@mui/material';
function FormPage(props) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let { id } = useParams(); 
  useEffect(() => {
    getDetailAshes(id);
  }, [id]);
  const handleBack = () => {
    navigate(`/dashboard/ashes/${id}`)
} 
  const getDetailAshes = async (id) => {
    if (id) {
      let resDetailUser = await props.getDetailAshes({ id: id });
      if (resDetailUser?.code) {
        setUser(resDetailUser.data)
      }
    }
  }
  return (
    <>
    
    <Helmet>
      <title> Sớ ngọc </title>
    </Helmet>

    <Container>
      {id && <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Stack direction="row" alignItems="center"  >
         <Iconify onClick={handleBack}  icon="openmoji:return" style={{width: "36px", cursor: "pointer"}} /> 
         <Typography variant="h4">{id ?  t("Xem và in sớ cầu siêu  / cầu an"): ""}
        </Typography></Stack> 
      </Stack> }
      <FormPrint user={user} updateAshes={props.updateAshes}/>
    </Container>
   

  </>
     
  )
}

const mapState = (state) => ({
  });
  const mapDispatch = (dispatch) => ({
    getDetailAshes: dispatch.ashes.getDetailAshes,
    updateAshes: dispatch.ashes.updateAshes,
  
  });
  export default connect(mapState, mapDispatch)(FormPage)
