import * as React from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { ROLES } from "src/utils/setting"
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormLabel,
  InputAdornment
} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import AlertDialog from 'src/components/dialog';
// sections
import { UserListHead, UserListToolbar } from 'src/sections/@dashboard/user';
// mock
import USERLIST from 'src/_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 4),
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function UserForm(props) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  let { id } = useParams();

  const [user, setUser] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const { control, handleSubmit,  formState: { errors }, setValue, watch, getValues } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const options = (props.listPagoda || []) 
  const [valueSelect, setValueSelect] = useState();
  const [inputValueSelect, setInputValueSelect] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };


  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  useEffect(() => {
    getDetailUser(id);
  }, [id]);
  const getDetailUser = async (id) => {
    if (id) {
      let resDetailUser = await props.getDetailUser({ id: id });
      if (resDetailUser?.code) {
        let data = resDetailUser.data
        // setUser(data)
        Object.keys(data).forEach((name, index )=> { 
          setValue(name, Object.values(data)[index])
        });

        if(data?.pagoda_id && data?.pagoda?.id) {
          await props.getListPagoda({
            filterObject: `{"id": "${data?.pagoda?.id}"}`
          })
          setValue("pagoda_id", data?.pagoda)
          setValueSelect({...options[0]})
        }
        
      }
    } else {
      await props.getListPagoda()
    }
  }
  const onChangeValue = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }
  const handleCreateUser = async (data) => {
    let resultCreate = await props.createUser({
      ...data,
      pagoda_id: data?.pagoda_id?.id || data?.pagoda_id || null,
      pagoda: null
    });
    if (resultCreate?.code) {
      navigate("/dashboard/user")
      toast("Tạo thành công")
    }
    else toast(resultCreate.message || resultCreate.error);
  }
  const handleUpdateUser = async (data) => {
    let resultUpdate = await props.updateUser({
      ...data,
      pagoda_id: data?.pagoda_id?.id || data?.pagoda_id || null,
      pagoda: null,
      password: null,
      id: id
    });
    if (resultUpdate?.code) {
      navigate("/dashboard/user")
      toast("Cập nhật thành công")
    }
    else toast(resultUpdate.message || resultUpdate.error);
  }

  const handleDeleteUser = async () => {
    let resultDelete = await props.deleteUser({ id: getValues()?.id });
    if (resultDelete?.code) {
      navigate("/dashboard/user")
      toast("Xoá thành công")
    }
    else toast(resultDelete.message || resultDelete.error);
  }
  const handleBack = () => {
    navigate("/dashboard/user")
  }

  const formUser = [
    // {
    //   type: "text",
    //   label: props.t('user_role'),
    //   name: "Role",
    //   defaultValue: user?.role_code || ROLES.ADMIN_ROLE,
    //   disable: true
    // },
    {
      type: "text",
      label: props.t('user_username'),
      name: "username",
      required: true,
    },    {
      type: "password",
      label: props.t('user_password'),
      name: "password",
      required: true,
      hidden: id ? true : false
    },  {
      type: "text",
      label: props.t('user_email'),
      name: "email",
      required: false,
    }, {
      type: "text",
      label: props.t('user_name'),
      name: "name",
      value: user?.name
    },
    {
      type: "autocomplete",
      label: props.t('user_pagoda_name'),
      name: "pagoda_id",
      required: true,
      hidden: props.dataToken?.role_code === ROLES.ADMIN_ROLE
    }]   
  return (
    <Card>
      <AlertDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
        }}
        title={props.t("confirm")}
        textBtnFirst={props.t("agree")}
        textBtnSecond={props.t("disagree")}
        content={`${props.t("are_you_sure_want_to_delete")} ${props.t("user")} ${getValues()?.username}`}
        handleActionFirst={() => {
          setOpenDialog(false);
        }}
        handleActionSecond={handleDeleteUser}
      />


      <StyledContent>
      <Paper
        style={{
          display: "grid",
          gridRowGap: "20px",
          // padding: "20px",
          // margin: "10px 300px",
        }}
      >
        {formUser.map((e) => {
            if (e.hidden) return null;
            if(e.type == "autocomplete") return <Controller
              name={e.name}
              control={control}
                  // value={watch("pagoda_id")}
                  rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
              render={({ field  }) => { return (
                <Autocomplete
                 {...field}
                  options={[...options]}
                  getOptionLabel={(option) => option.name}
                  // defaultValue={null}
                  value={options.find(e=> e.id === watch("pagoda_id")?.id) || null }
                  renderOption={(props, option) => {return <li {...props}>{`${option.name}-${option.address}`}</li>}}
                  onChange={(event, newValue) => {
                    setValue("pagoda_id", newValue)
                          // onChangeValue({
                          //   target: {
                          //     name: "pagoda_id",
                          //     value: newValue?.id,
                          //   }
                          // });
                      }}
                  onInputChange={(event, value, reason) => {
                    if(value != "undefined" && value) {
                      props.getListPagoda({
                        keyword: value
                      })
                    }
                  }}
                  renderInput={(params) => <TextField {...params} 
                  error={Boolean(errors[e.name])}
                  helperText={errors[e.name]?.message} 
                  required={true}
                  label={props.t('user_pagoda_name')}
                  />} 
                  /> 
              )}}
              
            />  
            return <>
            <Controller
                  name={e.name}
                  control={control}
                  rules={{ required: e.required ?  `${e.label} ${props.t('is_required')}`: ''}} // Add required rule
                  render={({ field }) => (
                    <TextField 
                      InputLabelProps={{ shrink: true }}
                      type={e.type === "password" ? (showPassword ? "text": "password") : e.type }
                      required={e.required}
                      label={e.label} 
                      error={Boolean(errors[e.name])} // Show error state if validation fails
                      helperText={errors[e.name]?.message} // Display error message
                      {...field} 
                      InputProps={e.type === "password" && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                /> 
            </> 
          })} 
          </Paper>
          <Stack direction="row" spacing={3} alignItems="center" justifyContent="right" style={{ marginTop: '24px' }}>
            {id ? <Button variant="contained" onClick={handleSubmit(handleUpdateUser)}>
              Cập nhật
            </Button>
              : <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleSubmit(handleCreateUser)} >
                Tạo
              </Button>}
            {id ? <Button variant="contained" color="error" startIcon={<Iconify icon="material-symbols:delete" />} onClick={() => setOpenDialog(true)}  >
              Xoá
            </Button> : null}
          </Stack>
      </StyledContent>
    </Card>
  );
}

const mapState = (state) => ({
  listAdmin: state.users.listAdmin,
});
const mapDispatch = (dispatch) => ({
  getListAdmin: dispatch.users.getListAdmin,
  getDetailUser: dispatch.users.getDetailUser,
  createUser: dispatch.users.createUser,
  updateUser: dispatch.users.updateUser,
  deleteUser: dispatch.users.deleteUser,
});
export default connect(mapState, mapDispatch)(UserForm)

